import axios from 'axios';
import { History } from 'history';
import { API_ENDPOINT } from '../constants';
import { setAlert } from './alert';
import { SEARCH_AGENCY, AGENCY_FAIL, GET_AGENCY, UPDATE_AGENCY, ADD_AGENCY, SET_AGENCY_NAME, GET_AGENCY_CLIENTS, UPDATE_AGENCY_CLIENTS, REMEMBER_RECENT_AGENCY, UPDATE_RECENT_AGENCY } from './types';
import { Agency, Client } from '../types';
import { AppThunk } from '../store';

interface SearchAgencyAction {
  type: typeof SEARCH_AGENCY,
  agencies: Agency[]
}

interface GetAgencyAction {
  type: typeof GET_AGENCY,
  agency: Agency
}

interface GetAgencyClientsAction {
  type: typeof GET_AGENCY_CLIENTS,
  clients: Client[]
}

interface UpdateAgencyClientsAction {
  type: typeof UPDATE_AGENCY_CLIENTS
}

interface AddAgencyAction {
  type: typeof ADD_AGENCY,
  agency: Agency
}

interface UpdateAgencyAction {
  type: typeof UPDATE_AGENCY,
  agency: Agency
}

interface SetAgencyNameAction {
  type: typeof SET_AGENCY_NAME,
  name: string
}

interface RememberRecentAgencyAction {
  type: typeof REMEMBER_RECENT_AGENCY,
  agency: Agency
}

interface UpdateRecentAgencyAction {
  type: typeof UPDATE_RECENT_AGENCY,
  agency: Agency
}

interface AgencyFailAction {
  type: typeof AGENCY_FAIL
}

export type AgencyActionTypes = SearchAgencyAction | GetAgencyAction | GetAgencyClientsAction | UpdateAgencyClientsAction | AddAgencyAction | UpdateAgencyAction | SetAgencyNameAction | RememberRecentAgencyAction | UpdateRecentAgencyAction | AgencyFailAction;

export const agencyFailAction = (): AgencyActionTypes => {
  return {
    type: AGENCY_FAIL
  }
}

export const searchAgenciesAction = (agencies: Agency[]): AgencyActionTypes => {
  return {
    type: SEARCH_AGENCY,
    agencies
  }
}

export const getAgencyAction = (agency: Agency): AgencyActionTypes => {
  return {
    type: GET_AGENCY,
    agency
  }
}

export const getAgencyClientsAction = (clients: Client[]): AgencyActionTypes => {
  return {
    type: GET_AGENCY_CLIENTS,
    clients
  }
}

export const updateAgencyClientsAction = (): AgencyActionTypes => {
  return {
    type: UPDATE_AGENCY_CLIENTS
  }
}

export const addAgencyAction = (agency: Agency): AgencyActionTypes => {
  return {
    type: ADD_AGENCY,
    agency
  }
}

export const updateAgencyAction = (agency: Agency): AgencyActionTypes => {
  return {
    type: UPDATE_AGENCY,
    agency
  }
}

export const updateRecentAgencyAction = (agency: Agency): AgencyActionTypes => {
  return {
    type: UPDATE_RECENT_AGENCY,
    agency
  }
}

export const rememberRecentAgencyAction = (agency: Agency): AgencyActionTypes => {
  return {
    type: REMEMBER_RECENT_AGENCY,
    agency
  }
}

export const setAgencyNameAction = (name: string): AgencyActionTypes => {
  return {
    type: SET_AGENCY_NAME,
    name
  }
}

const handleError = (err: any, dispatch: any) => {
  console.log(err);
  if (err.response) {
    dispatch(setAlert(err.response.statusText, 'danger'));
  } else {
    dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    dispatch(agencyFailAction());
  }
}

export const searchAgencies = (agency_name: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/agencies/search`, {
      params: { agency_name }
    });
    if (res.data.status) {
      dispatch(searchAgenciesAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(agencyFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const getAgency = (agency_id: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/agencies/edit`, {
      params: { agency_id }
    });
    if (res.data.status) {
      dispatch(getAgencyAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(agencyFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const getAgencyClients = (agency_id: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/dsrs/client_list`, {
      params: { agency_id }
    });
    if (res.data.status) {
      dispatch(getAgencyClientsAction(res.data.result));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(agencyFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const updateAgencyClients = (client_ids: Client[], history: History, agency_id: string): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/agencies/add_clients`, { agency_id, client_ids }, config);
    if (res.data.status) {
      dispatch(updateAgencyClientsAction());
      history.push(`/agencies/contacts/${agency_id}`);
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(agencyFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const addAgency = (agency: Agency, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/agencies/create`, agency, config);

    if (res.data.status) {
      dispatch(addAgencyAction(res.data.message));
      dispatch(setAlert('New agency added', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(agencyFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const updateAgency = (agency: Agency, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/agencies/update`, agency, config);

    if (res.data.status) {
      dispatch(updateAgencyAction(res.data.message));
      dispatch(updateRecentAgencyAction(res.data.message));
      dispatch(setAlert('Agency updated', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(agencyFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};