import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAIL, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL, RESEND_PASSWORD, RESEND_PASSWORD_FAIL, SELECT_USER, DESELECT_USER, SET_LOGIN_FORM_VALUE } from './types';
import { setAlert } from './alert';
import { API_ENDPOINT } from '../constants';
import { User, LoginForm } from '../types';
import { AppThunk } from '../store';

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS,
  uid: string
}

interface LoginFailAction {
  type: typeof LOGIN_FAIL
}

interface ResendPasswordAction {
  type: typeof RESEND_PASSWORD
}

interface ResendPasswordFailAction {
  type: typeof RESEND_PASSWORD_FAIL
}

interface SearchUsersAction {
  type: typeof SEARCH_USERS_SUCCESS,
  users: User[]
}

interface SearchUsersFailAction {
  type: typeof SEARCH_USERS_FAIL
}

interface SelectUserAction {
  type: typeof SELECT_USER,
  user: User
}

interface DeselectUserAction {
  type: typeof DESELECT_USER,
  user: User
}

interface SetLoginFormValue {
  type: typeof SET_LOGIN_FORM_VALUE,
  payload: { field: string, value: string }
}

export type UserActionTypes = LoginSuccessAction | LoginFailAction | ResendPasswordAction | ResendPasswordFailAction | SearchUsersAction | SearchUsersFailAction | SelectUserAction | DeselectUserAction | SetLoginFormValue;

const loginAction = (uid: string): UserActionTypes => {
  return {
    type: LOGIN_SUCCESS,
    uid
  }
}

const loginFailAction = (): UserActionTypes => {
  return { type: LOGIN_FAIL }
}

const resendPasswordAction = (): UserActionTypes => {
  return { type: RESEND_PASSWORD }
}

const resendPasswordFailAction = (): UserActionTypes => {
  return { type: RESEND_PASSWORD_FAIL }
}

const searchUsersAction = (users: User[]): UserActionTypes => {
  return {
    type: SEARCH_USERS_SUCCESS,
    users
  }
}

const searchUsersFailAction = (): UserActionTypes => {
  return { type: SEARCH_USERS_FAIL }
}

export const selectUserAction = (user: User): UserActionTypes => {
  return {
    type: SELECT_USER,
    user
  }
}

export const deselectUserAction = (user: User): UserActionTypes => {
  return {
    type: DESELECT_USER,
    user
  }
}

export const setLoginFormValueAction = (field: string, value: string): UserActionTypes => {
  return {
    type: SET_LOGIN_FORM_VALUE,
    payload: { field, value }
  }
}

export const login = (loginForm: LoginForm): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/users/check`, {
      params: loginForm
    });

    if (res.data.status) {
      dispatch(loginAction(res.data.uid));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(loginFailAction());
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    dispatch(loginFailAction());
  }
};

export const resendPassword = (loginForm: LoginForm): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/users/resend_password`, loginForm, config);

    if (res.data.status) {
      dispatch(resendPasswordAction());
      dispatch(setAlert('Password resent. Please provide and login', 'success'));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(resendPasswordFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(resendPasswordFailAction());
  }
}

export const searchUsers = (name: string, user_id: string): AppThunk => async dispatch => {
  const body = { name, user_id };
  try {
    const res = await axios.get(`${API_ENDPOINT}/users/search`, {
      params: body
    });

    if (res.data.status) {
      const users = res.data.message;
      if (users.length > 0) {
        dispatch(searchUsersAction(res.data.message));
      } else {
        dispatch(setAlert('No users match search term', 'danger'));
      }
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(searchUsersFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(searchUsersFailAction());
  }
};