import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { addCampaignAction } from '../actions/campaign';
import { setAlert } from '../actions/alert';
import ConcernContainer from '../components/ConcernContainer';
import { resetTGAction } from '../actions/dsr';
import { RootState } from '../reducers';
import { Campaign } from '../types';

interface ContainerProps extends RouteComponentProps<any> { }

const Concern: React.FC<ContainerProps> = ({ history }) => {

  const dispatch = useDispatch();

  const tgUpdate = useSelector<RootState, boolean>(state => state.dsr.tgUpdate);
  const campaign = useSelector<RootState, Campaign>(state => state.campaign.campaign);

  const onSubmit = () => {
    const { concern } = campaign;
    if (!concern || !concern.partOfCampaign) {
      dispatch(setAlert('Please provide a response', 'danger'));
      return;
    }
    if (concern.partOfCampaign !== 'Yes' && concern.otherInfo.length < 1 && !concern.others) {
      dispatch(setAlert('Please choose any other info', 'danger'));
      return;
    }

    dispatch(addCampaignAction(campaign.brand_id));
    if (tgUpdate) {
      dispatch(resetTGAction(false));
      history.go(-3);
    } else {
      history.go(-2);
    }

  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>Areas of Concern</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Areas of Concern</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ConcernContainer concern={campaign.concern} />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>Submit</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default withRouter(Concern);
