import React from 'react'
import { IonRadioGroup, IonItem, IonLabel, IonRadio, IonTextarea, IonList, IonCheckbox, IonInput } from '@ionic/react';
import { MARKET_DEFINITIONS, OTHERS } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMediaBriefAction, setKeyMarketsAction, setMarketDefinitionAction, setKeyOtherMarketAction,
  setGenresAction, selectOtherMarketAction
} from '../actions/campaign';
import { RootState } from '../reducers';
import { Campaign, Genre, Market } from '../types';


const CampaignContainer: React.FC = () => {

  const dispatch = useDispatch();

  const campaign = useSelector<RootState, Campaign>(state => state.campaign.campaign);
  const genres = useSelector<RootState, Genre[]>(state => state.campaign.genres);
  const markets = useSelector<RootState, Market[]>(state => state.campaign.markets);

  const onOtherMarketChange = (checked: boolean) => {
    dispatch(selectOtherMarketAction(checked));
    if (!checked) {
      dispatch(setKeyOtherMarketAction(OTHERS.value));
    }
  }

  return (
    <>
      <IonItem>
        <IonTextarea name='mediaBrief' value={campaign.mediaBrief}
          placeholder='Media Brief/Objectives'
          onIonChange={e => setMediaBriefAction(e.detail.value!)}></IonTextarea>
      </IonItem>

      <IonItem lines='none'>
        <IonLabel>Key Markets</IonLabel>
      </IonItem>

      <IonList>
        {markets.map(market => (
          <IonItem lines='none' key={market.id}>
            <IonLabel>{market.name}</IonLabel>
            <IonCheckbox checked={market.checked} slot="start" onIonChange={e => dispatch(setKeyMarketsAction(market.id, e.detail.checked))} />
          </IonItem>
        ))}
      </IonList>

      <IonItem lines='none'>
        <IonLabel>{OTHERS.name}</IonLabel>
        <IonCheckbox slot='start' checked={campaign.otherMarketChecked} onIonChange={e => onOtherMarketChange(e.detail.checked)} />
      </IonItem>
      {campaign.otherMarketChecked &&
        <IonItem>
          <IonInput placeholder='Other Market' type="text" name="otherMarket" value={campaign.otherMarket}
            onIonChange={e => dispatch(setKeyOtherMarketAction(e.detail.value!))}></IonInput>
        </IonItem>
      }

      <IonItem lines='none'>
        <IonLabel>Key Market Definition</IonLabel>
      </IonItem>
      <IonRadioGroup value={campaign.marketDefinition} onIonChange={(e) => dispatch(setMarketDefinitionAction(e.detail.value))}>
        {MARKET_DEFINITIONS.map(def =>
          <IonItem lines='none' key={def}>
            <IonLabel>{def}</IonLabel>
            <IonRadio mode='md' slot="start" value={def} />
          </IonItem>)
        }
      </IonRadioGroup>

      <IonItem lines='none'>
        <IonLabel>Genre Under Consideration</IonLabel>
      </IonItem>

      <IonList>
        {genres.map(genre => (
          <IonItem lines='none' key={genre.id}>
            <IonLabel>{genre.name}</IonLabel>
            <IonCheckbox checked={genre.checked} slot="start" onIonChange={e => dispatch(setGenresAction(genre.id, e.detail.checked))} />
          </IonItem>
        ))}
      </IonList>
      <IonItem lines='none'>
        <IonLabel>&nbsp;</IonLabel>
      </IonItem>
    </>
  )
}

export default CampaignContainer;
