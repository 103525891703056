import axios from 'axios';
import { History } from 'history';
import { API_ENDPOINT } from '../constants';
import { setAlert } from './alert';
import { SEARCH_CLIENT, CLIENT_FAIL, GET_CLIENT, UPDATE_CLIENT, ADD_CLIENT, SET_CLIENT_NAME, SET_AGENCY_FOR_CLIENT, REMEMBER_RECENT_CLIENT, UPDATE_RECENT_CLIENT } from './types';
import { Client, ApiError, Agency } from '../types';
import { AppThunk } from '../store';

interface SearchClientAction {
  type: typeof SEARCH_CLIENT,
  clients: Client[]
}

export interface GetClientAction {
  type: typeof GET_CLIENT,
  client: Client,
  selected_agencies: Agency[]
}

interface AddClientAction {
  type: typeof ADD_CLIENT,
  client: Client
}

interface UpdateClientAction {
  type: typeof UPDATE_CLIENT,
  client: Client
}

interface SetClientNameAction {
  type: typeof SET_CLIENT_NAME,
  name: string
}

interface RememberRecentClientAction {
  type: typeof REMEMBER_RECENT_CLIENT,
  client: Client
}

interface UpdateRecentClientAction {
  type: typeof UPDATE_RECENT_CLIENT,
  client: Client
}

interface SetAgencyIdsAction {
  type: typeof SET_AGENCY_FOR_CLIENT,
  agency: Agency
}

interface ClientFailAction {
  type: typeof CLIENT_FAIL,
  payload?: ApiError
}

export type ClientActionTypes = SearchClientAction | GetClientAction | AddClientAction | UpdateClientAction | SetClientNameAction | RememberRecentClientAction | UpdateRecentClientAction | SetAgencyIdsAction | ClientFailAction;

export const clientFailAction = (error?: ApiError): ClientActionTypes => {
  return {
    type: CLIENT_FAIL,
    payload: error
  }
}

export const searchClientsAction = (clients: Client[]): ClientActionTypes => {
  return {
    type: SEARCH_CLIENT,
    clients
  }
}

export const getClientAction = (client: Client, selected_agencies: Agency[]): ClientActionTypes => {
  return {
    type: GET_CLIENT,
    client,
    selected_agencies
  }
}

export const addClientyAction = (client: Client): ClientActionTypes => {
  return {
    type: ADD_CLIENT,
    client
  }
}

export const updateClientAction = (client: Client): ClientActionTypes => {
  return {
    type: UPDATE_CLIENT,
    client
  }
}

export const updateRecentClientAction = (client: Client): ClientActionTypes => {
  return {
    type: UPDATE_RECENT_CLIENT,
    client
  }
}

export const rememberRecentClientAction = (client: Client): ClientActionTypes => {
  return {
    type: REMEMBER_RECENT_CLIENT,
    client
  }
}

export const setClientNameAction = (name: string): ClientActionTypes => {
  return {
    type: SET_CLIENT_NAME,
    name
  }
}

export const setAgencyAction = (agency: Agency): ClientActionTypes => {
  return ({
    type: SET_AGENCY_FOR_CLIENT,
    agency
  })
};

const handleError = (err: any, dispatch: any) => {
  console.log(err);
  if (err.response) {
    dispatch(setAlert(err.response.statusText, 'danger'));
    dispatch(clientFailAction({ msg: err.response.statusText, status: err.response.status }));
  } else {
    dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    dispatch(clientFailAction());
  }
}

export const searchClients = (client_name: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/clients/search`, {
      params: { client_name }
    });
    if (res.data.status) {
      dispatch(searchClientsAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(clientFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const getClient = (client_id: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/clients/edit`, {
      params: { client_id }
    });
    if (res.data.status) {
      dispatch(getClientAction(res.data.message, res.data.selected_agencies));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(clientFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const addClient = (client: Client, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/clients/create`, client, config);

    if (res.data.status) {
      dispatch(addClientyAction(res.data.message));
      dispatch(setAlert('New client added', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(clientFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const updateClient = (client: Client, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/clients/update`, client, config);

    if (res.data.status) {
      dispatch(updateClientAction(res.data.message));
      dispatch(updateRecentClientAction(res.data.message));
      dispatch(setAlert('Client updated', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(clientFailAction());
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};