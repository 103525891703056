import { HOD_SUCCESS, HOD_FAIL, SET_HOD } from "../actions/types";
import { HoDActionTypes } from "../actions/hod";

export interface HodState {
  loading: boolean,
  hod: string
}

const initialState: HodState = {
  loading: true,
  hod: ''
};

export default function (state = initialState, action: HoDActionTypes) {
  switch (action.type) {
    case SET_HOD:
      return { ...state, hod: action.hod };
    case HOD_SUCCESS:
      return { ...state, loading: false };
    case HOD_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}
