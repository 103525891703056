import { SEARCH_AGENCY, GET_AGENCY, AGENCY_FAIL, UPDATE_AGENCY, SET_AGENCY_NAME, GET_AGENCY_CLIENTS, UPDATE_AGENCY_CLIENTS, REMEMBER_RECENT_AGENCY, UPDATE_RECENT_AGENCY, ADD_AGENCY } from "../actions/types";
import { Agency, Client } from "../types";
import { AgencyActionTypes } from "../actions/agency";

export interface AgencyState {
  loading: boolean,
  agencies: Agency[],
  agency: Agency,
  agencyClients: Client[],
  recentAgencies: Agency[]
};

const initialState: AgencyState = {
  loading: true,
  agencies: [],
  agency: {} as Agency,
  agencyClients: [],
  recentAgencies: localStorage.recent_agencies ? JSON.parse(localStorage.recent_agencies) : []
};

export default function (state = initialState, action: AgencyActionTypes) {
  switch (action.type) {
    case SEARCH_AGENCY:
      return { ...state, agencies: action.agencies, loading: false };
    case GET_AGENCY:
      return { ...state, agency: action.agency, loading: false };
    case GET_AGENCY_CLIENTS:
      return { ...state, agencyClients: action.clients, loading: false };
    case ADD_AGENCY:
      return { ...state, agencies: [...state.agencies, action.agency] };
    case UPDATE_AGENCY:
      return {
        ...state, agency: action.agency,
        agencies: state.agencies.map(agency =>
          agency.agency_id === action.agency.agency_id ? action.agency : agency),
        loading: false
      };
    case AGENCY_FAIL:
      return { ...state, loading: false };
    case SET_AGENCY_NAME:
      return { ...state, agency: { ...state.agency, agency_name: action.name } };
    case REMEMBER_RECENT_AGENCY:
      if (state.recentAgencies.find(item => item.agency_id === action.agency.agency_id)) {
        return state;
      }
      let recentAgencies = [...state.recentAgencies];
      if (recentAgencies.length > 4) {
        recentAgencies.splice(0, 1);
      }
      recentAgencies = recentAgencies.concat(action.agency);
      localStorage.setItem('recent_agencies', JSON.stringify(recentAgencies));
      return { ...state, recentAgencies }
    case UPDATE_RECENT_AGENCY:
      if (state.recentAgencies.find(item => item.agency_id === action.agency.agency_id)) {
        const recentAgencies = state.recentAgencies.map(agency =>
          agency.agency_id === action.agency.agency_id ? action.agency : agency);
        localStorage.setItem('recent_agencies', JSON.stringify(recentAgencies));
        return { ...state, recentAgencies };
      }
      return state;
    case UPDATE_AGENCY_CLIENTS:
    default:
      return state;
  }
}