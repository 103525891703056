import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAgencyNameAction } from '../actions/agency';
import { RootState } from '../reducers';
import { Agency } from '../types';

const AgencyContainer: React.FC = () => {

  const dispatch = useDispatch();
  const agency = useSelector<RootState, Agency>(state => state.agency.agency);

  return (
    <React.Fragment>
      <IonItem>
        <IonLabel position="stacked">Agency Name<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="text" name="agency_name" value={agency.agency_name}
          onIonChange={e => dispatch(setAgencyNameAction(e.detail.value!))}></IonInput>
      </IonItem>
    </React.Fragment>
  );
};

export default AgencyContainer;
