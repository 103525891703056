import axios from 'axios';
import { SET_MEDIA_BRIEF, SET_KEY_MARKETS, SELECT_OTHER_MARKET, SET_KEY_OTHER_MARKET, SET_MARKET_DEFINITION, SET_CAMPAIGN_DATE, SET_GENRES, SET_CONCERNS, GET_BRAND_FOR_CAMPAIGN, GET_MARKETS, GET_GENRES, ADD_CAMPAIGN, REMOVE_CAMPAIGN, SET_PART_OF_CAMPAIGN, SET_YES_OTHER_INFO, SET_OTHER_INFO, SET_OTHERS } from "./types";
import { Market, Genre, Concern, Brand } from "../types";
import { API_ENDPOINT } from "../constants";
import { setAlert } from "./alert";
import { AppThunk } from '../store';

interface GetBrandAction {
  type: typeof GET_BRAND_FOR_CAMPAIGN,
  brand: Brand
}

interface SetMediaBriefAction {
  type: typeof SET_MEDIA_BRIEF,
  brief: string
}

interface SetKeyMarketAction {
  type: typeof SET_KEY_MARKETS,
  payload: { id: number, checked: boolean }
}

interface SelectOtherMarketAction {
  type: typeof SELECT_OTHER_MARKET,
  checked: boolean
}

interface SetKeyOtherMarketAction {
  type: typeof SET_KEY_OTHER_MARKET,
  other: string
}

interface SetMarketDefinitionAction {
  type: typeof SET_MARKET_DEFINITION,
  definition: string
}

interface SetCampaignDateAction {
  type: typeof SET_CAMPAIGN_DATE,
  campaignDate: string
}

interface SetGenresAction {
  type: typeof SET_GENRES,
  payload: { id: number, checked: boolean }
}

interface SetConcernAction {
  type: typeof SET_CONCERNS,
  payload: { id: number, concern: Concern }
}

interface GetMarketsAction {
  type: typeof GET_MARKETS,
  markets: Market[]
}

interface GetGenresAction {
  type: typeof GET_GENRES,
  genres: Genre[]
}

interface AddCampaignAction {
  type: typeof ADD_CAMPAIGN,
  brand_id: number
}

interface RemoveCampaignAction {
  type: typeof REMOVE_CAMPAIGN,
  brand_id: number
}

interface SetPartOfCampaign {
  type: typeof SET_PART_OF_CAMPAIGN,
  partOfCampaign: string
}

interface SetYesOtherInfo {
  type: typeof SET_YES_OTHER_INFO,
  otherInfo: string
}

interface SetOtherInfo {
  type: typeof SET_OTHER_INFO,
  payload: { otherInfo: string, checked: boolean }
}

interface SetOthers {
  type: typeof SET_OTHERS,
  others: string
}

export type CampaignActionTypes = GetBrandAction | SetMediaBriefAction | SetKeyMarketAction | SetKeyOtherMarketAction | SelectOtherMarketAction | SetMarketDefinitionAction | SetCampaignDateAction | SetGenresAction | SetConcernAction | GetMarketsAction | GetGenresAction | AddCampaignAction | RemoveCampaignAction | SetPartOfCampaign | SetYesOtherInfo | SetOtherInfo | SetOthers;

export const getBrandAction = (brand: Brand): CampaignActionTypes => {
  return ({
    type: GET_BRAND_FOR_CAMPAIGN,
    brand
  })
}

export const setMediaBriefAction = (brief: string): CampaignActionTypes => {
  return ({
    type: SET_MEDIA_BRIEF,
    brief
  })
};

export const setKeyMarketsAction = (id: number, checked: boolean): CampaignActionTypes => {
  return ({
    type: SET_KEY_MARKETS,
    payload: { id, checked }
  })
};

export const selectOtherMarketAction = (checked: boolean): CampaignActionTypes => {
  return ({
    type: SELECT_OTHER_MARKET,
    checked
  })
}

export const setKeyOtherMarketAction = (other: string): CampaignActionTypes => {
  return ({
    type: SET_KEY_OTHER_MARKET,
    other
  })
};

export const setMarketDefinitionAction = (definition: string): CampaignActionTypes => {
  return ({
    type: SET_MARKET_DEFINITION,
    definition
  })
};

export const setCampaignDateAction = (campaignDate: string): CampaignActionTypes => {
  return ({
    type: SET_CAMPAIGN_DATE,
    campaignDate
  })
};

export const setGenresAction = (id: number, checked: boolean): CampaignActionTypes => {
  return ({
    type: SET_GENRES,
    payload: { id, checked }
  })
};

export const setConcernsAction = (id: number, concern: Concern): CampaignActionTypes => {
  return ({
    type: SET_CONCERNS,
    payload: { id, concern }
  })
}

const getMarketsAction = (markets: Market[]): CampaignActionTypes => {
  return ({
    type: GET_MARKETS,
    markets
  })
}

const getGenresAction = (genres: Genre[]): CampaignActionTypes => {
  return ({
    type: GET_GENRES,
    genres
  })
}

export const addCampaignAction = (brand_id: number): CampaignActionTypes => {
  return ({
    type: ADD_CAMPAIGN,
    brand_id
  })
};

export const removeCampaignAction = (brand_id: number): CampaignActionTypes => {
  return ({
    type: REMOVE_CAMPAIGN,
    brand_id
  })
};

export const getMarkets = (): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/dsrs/markets`);
    if (res.data.status) {
      dispatch(getMarketsAction(res.data.markets));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const getGenres = (): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/dsrs/channels`);
    if (res.data.status) {
      dispatch(getGenresAction(res.data.channels));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const setPartOfCampaign = (partOfCampaign: string): CampaignActionTypes => {
  return ({
    type: SET_PART_OF_CAMPAIGN,
    partOfCampaign
  })
};

export const setYesOtherInfo = (otherInfo: string): CampaignActionTypes => {
  return ({
    type: SET_YES_OTHER_INFO,
    otherInfo
  })
};

export const setOtherInfo = (otherInfo: string, checked: boolean): CampaignActionTypes => {
  return ({
    type: SET_OTHER_INFO,
    payload: { checked, otherInfo }
  })
};

export const setOthers = (others: string): CampaignActionTypes => {
  return ({
    type: SET_OTHERS,
    others
  })
};
