import { SEARCH_CLIENT, GET_CLIENT, CLIENT_FAIL, UPDATE_CLIENT, SET_CLIENT_NAME, SET_AGENCY_FOR_CLIENT, ADD_CLIENT, REMEMBER_RECENT_CLIENT, UPDATE_RECENT_CLIENT } from "../actions/types";
import { Client } from "../types";
import { ClientActionTypes } from "../actions/client";

export interface ClientState {
  loading: boolean,
  clients: Client[],
  client: Client,
  recentClients: Client[]
}

const initialState: ClientState = {
  loading: true,
  clients: [] as Client[],
  client: {} as Client,
  recentClients: localStorage.recent_clients ? JSON.parse(localStorage.recent_clients) : []
};

export default function (state = initialState, action: ClientActionTypes) {
  switch (action.type) {
    case ADD_CLIENT:
      return {
        ...state, clients: [...state.clients, action.client]
      }
    case SEARCH_CLIENT:
      return { ...state, clients: action.clients, loading: false };
    case GET_CLIENT:
      return {
        ...state, client: { ...action.client, selected_agencies: action.selected_agencies, agency_ids: action.selected_agencies?.map(item => item.agency_id) }, loading: false
      };
    case SET_CLIENT_NAME:
      return { ...state, client: { ...state.client, client_name: action.name } };
    case SET_AGENCY_FOR_CLIENT:
      if (state.client.agency_ids?.find(item => item === action.agency.agency_id)) {
        return {
          ...state, client: {
            ...state.client,
            selected_agencies: state.client.selected_agencies?.filter(item => item.agency_id !== action.agency.agency_id),
            agency_ids: state.client.agency_ids.filter(item => item !== action.agency.agency_id)
          }
        };
      }
      if (state.client.selected_agencies && state.client.agency_ids) {
        return {
          ...state, client: {
            ...state.client,
            selected_agencies: [...state.client.selected_agencies, action.agency],
            agency_ids: [...state.client.agency_ids, action.agency.agency_id]
          }
        };
      }
      return {
        ...state, client: {
          ...state.client,
          selected_agencies: [action.agency],
          agency_ids: [action.agency.agency_id]
        }
      };

    case UPDATE_CLIENT:
      return {
        ...state, client: action.client,
        clients: state.clients.map(client =>
          client.client_id === action.client.client_id ? action.client : client),
        loading: false
      };
    case REMEMBER_RECENT_CLIENT:
      if (state.recentClients.find(item => item.client_id === action.client.client_id)) {
        return state;
      }
      let recentClients = [...state.recentClients];
      if (recentClients.length > 4) {
        recentClients.splice(0, 1);
      }
      recentClients = recentClients.concat([action.client]);
      localStorage.setItem('recent_clients', JSON.stringify(recentClients));
      return { ...state, recentClients }
    case UPDATE_RECENT_CLIENT:
      if (state.recentClients.find(item => item.client_id === action.client.client_id)) {
        const recentClients = state.recentClients.map(client =>
          client.client_id === action.client.client_id ? action.client : client);
        localStorage.setItem('recent_clients', JSON.stringify(recentClients));
        return { ...state, recentClients };
      }
      return state;
    case CLIENT_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}