import axios from 'axios';
import { LIST_DSR, SEARCH_DSR, DSR_DETAIL, LIST_DSR_COMPLETE, SEARCH_DSR_COMPLETE, LIST_DSR_FAIL, DSR_DETAIL_FAIL, SET_LOADING } from "./types";
import { Dsr, DsrDetail } from "../types";
import { API_ENDPOINT } from '../constants';
import { setAlert } from './alert';
import { AppThunk } from '../store';

interface SetLoadingAction {
  type: typeof SET_LOADING
}

interface ListDsrAction {
  type: typeof LIST_DSR,
  payload: { dsrs: Dsr[], offset: number }
}

interface SearchDsrAction {
  type: typeof SEARCH_DSR,
  payload: { dsrs: Dsr[], offset: number }
}

interface DsrDetailAction {
  type: typeof DSR_DETAIL,
  dsrDetail: DsrDetail
}

interface ListDsrFailAction {
  type: typeof LIST_DSR_FAIL
}

interface DsrDetailFailAction {
  type: typeof DSR_DETAIL_FAIL
}

interface ListDsrCompleteAction {
  type: typeof LIST_DSR_COMPLETE
}

interface SearchDsrCompleteAction {
  type: typeof SEARCH_DSR_COMPLETE
}

export type ReviewActionTypes = SetLoadingAction | ListDsrAction | SearchDsrAction | DsrDetailAction | ListDsrCompleteAction | SearchDsrCompleteAction | ListDsrFailAction | DsrDetailFailAction;

export const setLoading = (): ReviewActionTypes => {
  return ({ type: SET_LOADING })
}

const listDsrAction = (dsrs: Dsr[], offset: number): ReviewActionTypes => {
  return ({
    type: LIST_DSR,
    payload: { dsrs, offset }
  })
}

const searchDsrAction = (dsrs: Dsr[], offset: number): ReviewActionTypes => {
  return ({
    type: SEARCH_DSR,
    payload: { dsrs, offset }
  })
}

const dsrDetailAction = (dsrDetail: DsrDetail): ReviewActionTypes => {
  return ({
    type: DSR_DETAIL,
    dsrDetail
  })
}

const listDsrCompleteAction = (): ReviewActionTypes => {
  return ({
    type: LIST_DSR_COMPLETE
  })
}

const searchDsrCompleteAction = (): ReviewActionTypes => {
  return ({
    type: SEARCH_DSR_COMPLETE
  })
}

const listDsrFailAction = (): ReviewActionTypes => {
  return ({
    type: LIST_DSR_FAIL
  })
}

const dsrDetailrFailAction = (): ReviewActionTypes => {
  return ({
    type: DSR_DETAIL_FAIL
  })
}

export const getAllDsrs = (user_id: string, offset = 0): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/dsrs/all`, {
      params: { user_id, offset }
    });
    if (res.data.status) {
      dispatch(listDsrAction(res.data.result, offset));
    } else {
      dispatch(listDsrCompleteAction());
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
      dispatch(listDsrFailAction());
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const getDsrDetails = (dsr_id: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/dsrs/details`, {
      params: { dsr_id }
    });
    if (res.data.status) {
      dispatch(dsrDetailAction(res.data.result));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
      dispatch(dsrDetailrFailAction());
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const searchDsrs = (search_term: string, user_id: string, offset = 0): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/dsrs/search`, {
      params: { search_term, user_id, offset }
    });
    if (res.data.status) {
      dispatch(searchDsrAction(res.data.result, offset));
    } else {
      dispatch(searchDsrCompleteAction())
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert(err.response.statusText, 'danger'));
    dispatch(listDsrFailAction());
  }
};