import React from 'react';
import { IonItem, IonList, IonLabel, IonCheckbox, IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { setDsrAgencyClientAction } from '../actions/dsr';
import { searchClients } from '../actions/client';
import { personAdd } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import Search from './Search';
import { RootState } from '../reducers';
import { Client } from '../types';

interface ContainerProps {
  agencyClients: Client[],
}

const SelectAgencyClientContainer: React.FC<ContainerProps> = ({ agencyClients }) => {

  const dispatch = useDispatch();
  const clients = useSelector<RootState, Client[]>(state => state.client.clients);
  const loading = useSelector<RootState, boolean>(state => state.client.loading);

  const history = useHistory();

  return (
    <>
      <Search placeholder='Clients' searchAction={searchClients} />
      <IonItem lines='none'>
        <IonButton fill='clear' onClick={() => history.push('/agencies/clients/add')}><IonIcon slot='start' icon={personAdd}></IonIcon>Add Client</IonButton>
      </IonItem>
      <IonList className='ion-padding'>
        {agencyClients.map(client => (
          <IonItem lines='none' key={client.client_id}>
            <IonLabel>{client.client_name}</IonLabel>
            <IonCheckbox slot="start" onIonChange={e => dispatch(setDsrAgencyClientAction(e.detail.checked, client))} />
          </IonItem>
        ))}
      </IonList>
      {loading && clients === null ? (
        <IonSpinner />
      ) : (
          <IonList className='ion-padding'>
            {clients.filter(item => !agencyClients.find(el => el.client_id === item.client_id)).map(client => (
              <IonItem lines='none' key={client.client_id}>
                <IonLabel>{client.client_name}</IonLabel>
                <IonCheckbox slot="start" onIonChange={e => dispatch(setDsrAgencyClientAction(e.detail.checked, client))} />
              </IonItem>
            ))}
          </IonList>
        )}
    </>
  );
};

export default SelectAgencyClientContainer;