import React from 'react';
import { IonItem, IonSpinner, IonList, IonLabel, IonCheckbox, IonListHeader } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { searchUsers, selectUserAction, deselectUserAction } from '../actions/user';
import Search from './Search';
import { RootState } from '../reducers';
import { User } from '../types';

const SearchUserContainer: React.FC = () => {

  const dispatch = useDispatch();

  const loading = useSelector<RootState, boolean>(state => state.user.loading);
  const users = useSelector<RootState, User[]>(state => state.user.users);
  const user_id = useSelector<RootState, string>(state => state.user.user_id);
  const taggedUsers = useSelector<RootState, User[]>(state => state.user.taggedUsers);

  return (
    <>
      <Search placeholder='Users' searchAction={searchUsers}></Search>
      {loading && users === null ? (
        <IonSpinner />
      ) : (
          <IonList lines='none' className='ion-padding'>
            {users.filter(user => user.user_id !== +user_id).map(user => (
              <IonItem key={user.user_id}>
                <IonLabel>{user.name}</IonLabel>
                <IonCheckbox slot="start" checked={user.checked} onIonChange={() =>
                  dispatch(selectUserAction(user))} />
              </IonItem>
            ))}
          </IonList>
        )}
      {taggedUsers && taggedUsers.length > 0 && (
        <IonList className='ion-padding'>
          <IonListHeader>
            <IonLabel>Tagged Users</IonLabel>
          </IonListHeader>
          {taggedUsers.map(user => (
            <IonItem lines='none' key={user.user_id}>
              <IonLabel>{user.name}</IonLabel>
              <IonCheckbox checked={user.checked} slot="start" onIonChange={() =>
                dispatch(deselectUserAction(user))} />
            </IonItem>
          ))}
        </IonList>
      )}
    </>
  );
};

export default SearchUserContainer;