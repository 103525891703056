import React from 'react';
import './LoginContainer.css';
import { IonItem, IonTextarea } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers';
import { setHoDAction } from '../actions/hod';

const HoDContainer: React.FC = () => {

  const dispatch = useDispatch();
  const hod = useSelector((state: RootState) => state.hod.hod);

  return (
    <IonItem>
      <IonTextarea name='highlights' value={hod}
        placeholder='Highlights of the day'
        onIonChange={e => dispatch(setHoDAction(e.detail.value!))}></IonTextarea>
    </IonItem>
  );
};

export default HoDContainer;

