import React, { useState } from 'react';
import { IonItem, IonList, IonSearchbar, IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllDsrs, searchDsrs } from '../actions/review';
import { setAlert } from '../actions/alert';
import DsrList from './DsrList';
import { searchOutline } from 'ionicons/icons';
import { RootState } from '../reducers';
import { Dsr } from '../types';

const DsrListContainer: React.FC = () => {

  const dispatch = useDispatch();

  const loadMore = useSelector<RootState, boolean>(state => state.review.loadMore);
  const dsrs = useSelector<RootState, Dsr[]>(state => state.review.dsrs);
  const user_id = useSelector<RootState, string>(state => state.user.user_id);

  const [searchText, setSearchText] = useState('');

  const onSearchClicked = (e: any) => {
    e.preventDefault();
    if (searchText.length > 2) {
      dispatch(searchDsrs(searchText, user_id, 0));
    } else {
      dispatch(setAlert('Enter at least 3 characters', 'danger'));
    }
  };

  const onCleared = () => {
    setSearchText('');
    dispatch(getAllDsrs(user_id, 0));
  }

  const getMoreDsrs = ($event: CustomEvent<void>) => {
    if (searchText.length > 2) {
      dispatch(searchDsrs(searchText, user_id, dsrs.length));
    } else {
      dispatch(getAllDsrs(user_id, dsrs.length));
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  return (
    <React.Fragment>
      <IonItem lines='none'>
        <IonSearchbar value={searchText} placeholder='Brand/Client/Agency/Submitter'
          onKeyDown={e => e.key === 'Enter' && onSearchClicked(e)}
          onIonChange={e => setSearchText(e.detail.value!)}
          onIonCancel={() => onCleared()}
          onIonClear={() => onCleared()}></IonSearchbar>
        <IonButton fill='clear' onClick={e => onSearchClicked(e)}><IonIcon slot='icon-only' icon={searchOutline}></IonIcon></IonButton>
      </IonItem>
      <IonList>
        <DsrList dsrs={dsrs} />
      </IonList>
      <IonInfiniteScroll threshold="100px" disabled={!loadMore}
        onIonInfinite={(e: CustomEvent<void>) => getMoreDsrs(e)}>
        <IonInfiniteScrollContent
          loadingText="Loading more DSRs...">
        </IonInfiniteScrollContent>
      </IonInfiniteScroll>

    </React.Fragment>
  );
};

export default DsrListContainer;