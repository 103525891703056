import React from 'react';
import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import './RegisterCallContainer.css';
import { useDispatch, useSelector } from 'react-redux';
import { setDsrDateAction } from '../actions/dsr';
import { setAlert } from '../actions/alert';
import { RootState } from '../reducers';

const RegisterCallContainer: React.FC = () => {

  const dispatch = useDispatch();
  const dsrDate = useSelector<RootState, string>(state => state.dsr.dsrDate);

  const history = useHistory();

  const today = moment().format('DD/MM/YYYY');
  const yesterday = moment().subtract(1, 'days').format('DD/MM/YYYY');

  const validateAndProceed = (url: string) => {
    if (dsrDate) {
      history.push(url);
    } else {
      dispatch(setAlert('Please provide DSR Date to continue', 'danger'));
    }
  }

  return (
    <React.Fragment>
      <IonItem className='dsr-date'>
        <IonLabel>DSR Date</IonLabel>
        <IonSelect mode='md' value={dsrDate} placeholder='Select' onIonChange={e => dispatch(setDsrDateAction(e.detail.value))}>
          <IonSelectOption value={yesterday}>{yesterday}</IonSelectOption>
          <IonSelectOption value={today}>{today}</IonSelectOption>
        </IonSelect>
      </IonItem>
      <IonButton className='home-button' expand='block' onClick={() => {
        validateAndProceed('/clients');
      }}>Client</IonButton>
      <IonButton className='home-button' expand='block' onClick={() => {
        validateAndProceed('/agencies');
      }} >Agency</IonButton>
    </React.Fragment>
  );
};

export default RegisterCallContainer;
