import { IonInput, IonItem, IonLabel, IonSpinner, IonList, IonCheckbox, IonText } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchAgencies } from '../actions/agency';
import { setAgencyAction, setClientNameAction } from '../actions/client';
import Search from './Search';
import { RootState } from '../reducers';
import { Agency, Client } from '../types';

interface ContainerProps {
  agency: boolean
}

const ClientContainer: React.FC<ContainerProps> = ({ agency }) => {

  const dispatch = useDispatch();

  const agencies = useSelector<RootState, Agency[]>(state => state.agency.agencies);
  const client = useSelector<RootState, Client>(state => state.client.client);
  const loading = useSelector<RootState, boolean>(state => state.agency.loading);

  return (
    <React.Fragment>
      <IonItem>
        <IonLabel position="stacked">Client Name<IonText color='danger'>*</IonText></IonLabel>
        <IonInput required type="text" name="client_name" value={client.client_name}
          onIonChange={e => dispatch(setClientNameAction(e.detail.value!))}></IonInput>
      </IonItem>
      {!agency && (
        <>
          <IonItem lines='none'>
            <IonLabel>Choose Agencies</IonLabel>
          </IonItem>
          <Search placeholder='Agencies' searchAction={searchAgencies} />
          {client.selected_agencies && (
            <IonList>
              {client.selected_agencies.map(agency => (
                <IonItem lines='none' key={agency.agency_id}>
                  <IonLabel>{agency.agency_name}</IonLabel>
                  <IonCheckbox checked={true} slot="start" onIonChange={e => dispatch(setAgencyAction(agency))} />
                </IonItem>
              ))}
            </IonList>
          )}
          {loading && agencies === null ? (
            <IonSpinner />
          ) : (
              <IonList>
                {agencies.filter(item => !client.selected_agencies || !client.selected_agencies.find(el => el.agency_id === item.agency_id)).map(agency => (
                  <IonItem lines='none' key={agency.agency_id}>
                    <IonLabel>{agency.agency_name}</IonLabel>
                    <IonCheckbox slot="start" onIonChange={e => dispatch(setAgencyAction(agency))} />
                  </IonItem>
                ))}
              </IonList>
            )}
        </>
      )}
    </React.Fragment>
  );
};

export default ClientContainer;
