import React from 'react';
import './HomeContainer.css';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';

const HomeContainer: React.FC = () => {

  const history = useHistory();

  return (
    <React.Fragment>
      <IonButton className='home-button' expand='block' onClick={() => {
        history.push('/tag');
      }}>Register DSR</IonButton>
      <IonButton className='home-button' expand='block' onClick={() => {
        history.push('/dsrs');
      }} >Review DSR</IonButton>
      <IonButton className='home-button' expand='block' onClick={() => {
        history.push('/hod');
      }} >HoD</IonButton>
    </React.Fragment>
  );
};

export default HomeContainer;
