import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import React from 'react';
import './Home.css';
import RegisterCallContainer from '../components/RegisterCallContainer';

const RegisterCall: React.FC = () => {
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='home'/>
          </IonButtons>
          <IonTitle>Register Call</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Register Call</IonTitle>
          </IonToolbar>
        </IonHeader>
        <RegisterCallContainer />
      </IonContent>
    </IonPage>
  );
};

export default RegisterCall;
