import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DsrSummaryContainer from '../components/DsrSummaryContainer';
import { addDsr } from '../actions/dsr';
import { Redirect, useHistory } from 'react-router-dom';
import { RootState } from '../reducers';
import { User, Campaign } from '../types';
import { DsrState } from '../reducers/dsr';

const DsrSummary: React.FC = () => {

  const dispatch = useDispatch();

  const taggedUsers = useSelector<RootState, User[]>(state => state.user.taggedUsers);
  const dsr = useSelector<RootState, DsrState>(state => state.dsr);
  const campaigns = useSelector<RootState, Campaign[]>(state => state.campaign.campaigns);
  const history = useHistory();

  const onSubmit = () => {
    dispatch(addDsr(dsr.dsrForm));
    history.push('/home');
  }

  return <IonPage>
    <IonHeader translucent>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/dsrs" />
        </IonButtons>
        <IonTitle>DSR Summary</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>
      <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">DSR Summary</IonTitle>
        </IonToolbar>
      </IonHeader>
      {dsr && dsr.dsr && campaigns ? <DsrSummaryContainer dsr={dsr} campaigns={campaigns} taggedUsers={taggedUsers} /> :
        <Redirect to='/home' />}
    </IonContent>
    <IonFooter>
      <IonButton expand='block' onClick={() =>
        onSubmit()
      }>Submit DSR</IonButton>
    </IonFooter>
  </IonPage>
};

export default DsrSummary;