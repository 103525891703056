import React, { useState } from 'react'
import { IonItem, IonLabel, IonRadioGroup, IonRadio, IonCheckbox, IonList, IonListHeader, IonTextarea, IonText } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { setPartOfCampaign, setYesOtherInfo, setOthers, setOtherInfo } from '../actions/campaign';
import { CONCERNS, DEFAULT_OTHER_INFO, OTHERS } from '../constants';
import { Concern } from '../types';

interface ContainerProps {
  concern: Concern
}

const ConcernContainer: React.FC<ContainerProps> = ({ concern }) => {

  const dispatch = useDispatch();

  const [othersChecked, checkOthers] = useState(concern && concern.others ? true : false);

  return (
    <>
      <IonItem lines='none'>
        <IonLabel className='ion-text-wrap'>Is your channel part of the campaign being discusssed?</IonLabel>
      </IonItem>
      <IonRadioGroup value={concern ? concern.partOfCampaign : ''} onIonChange={(e) => { dispatch(setPartOfCampaign(e.detail.value)); checkOthers(false) }}>
        {CONCERNS.map(concern =>
          <IonItem lines='none' key={concern}>
            <IonLabel>{concern}</IonLabel>
            <IonRadio mode='md' slot="start" value={concern} />
          </IonItem>)
        }
      </IonRadioGroup>
      {concern && concern.partOfCampaign !== 'Yes' &&
        <IonList>
          <IonListHeader>
            <IonLabel><IonText><h2>Any Other Information</h2></IonText></IonLabel>
          </IonListHeader>
          {DEFAULT_OTHER_INFO.map((item: any) => (
            <IonItem lines='none' key={item}>
              <IonLabel>{item}</IonLabel>
              <IonCheckbox checked={concern.otherInfo && concern.otherInfo.indexOf(item) > -1} slot="start" onIonChange={e => dispatch(setOtherInfo(item, e.detail.checked))} />
            </IonItem>
          ))}
          <IonItem lines='none'>
            <IonLabel>{OTHERS.name}</IonLabel>
            <IonCheckbox slot='start' checked={othersChecked} onIonChange={e => checkOthers(e.detail.checked)} />
          </IonItem>
          {othersChecked &&
            <IonItem>
              <IonTextarea name="others" value={concern ? concern.others : ''} placeholder={OTHERS.name}
                onIonChange={e => dispatch(setOthers(e.detail.value!))}></IonTextarea>
            </IonItem>}
          <IonLabel>&nbsp;</IonLabel>
        </IonList>
      }
      {concern && concern.partOfCampaign === 'Yes' &&
        <IonItem>
          <IonTextarea name='yesInfo' value={concern ? concern.otherInfo && concern.otherInfo[0] : ''}
            placeholder='Any Other Information'
            onIonChange={e => dispatch(setYesOtherInfo(e.detail.value!))}></IonTextarea>
        </IonItem>
      }
    </>
  )
}

export default ConcernContainer;