import axios from 'axios';
import { History } from 'history';
import { HOD_SUCCESS, HOD_FAIL, SET_HOD } from './types';
import { setAlert } from './alert';
import { API_ENDPOINT } from '../constants';
import { AppThunk } from '../store';

export interface SetHoDAction {
  type: typeof SET_HOD,
  hod: string
}

interface HoDSucessAction {
  type: typeof HOD_SUCCESS,
  hod: string
}

interface HoDFailAction {
  type: typeof HOD_FAIL
}

export type HoDActionTypes = SetHoDAction | HoDSucessAction | HoDFailAction;

export const setHoDAction = (hod: string): HoDActionTypes => {
  return {
    type: SET_HOD,
    hod
  }
}

const sendHoDAction = (hod: string): HoDActionTypes => {
  return {
    type: HOD_SUCCESS,
    hod
  }
}

const hoDFailAction = (): HoDActionTypes => {
  return {
    type: HOD_FAIL
  }
}

export const sendHoD = (highlights: string, user_id: string, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { highlights, user_id };
  try {
    const res = await axios.post(`${API_ENDPOINT}/highlights`, body, config);

    if (res.data.status) {
      dispatch(sendHoDAction(res.data.message));
      dispatch(setAlert('Highlight of the day added', 'success'));
      history.push('/home');
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(hoDFailAction());
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    dispatch(hoDFailAction());
  }
};