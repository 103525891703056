import { GET_BRANDS, BRAND_FAIL, UPDATE_BRAND, SET_BRAND_NAME, SET_TARGET_GROUP, ADD_BRAND, SELECT_BRAND, SET_BRAND_CLIENT_ID, GET_BRAND_FOR_EDIT } from "../actions/types";
import { Brand } from "../types";
import { BrandActionTypes } from "../actions/brand";

export interface BrandState {
  loading: boolean,
  brands: Brand[],
  brand: Brand
}

const initialState: BrandState = {
  loading: true,
  brands: [],
  brand: {} as Brand
};

export default function (state = initialState, action: BrandActionTypes) {
  switch (action.type) {
    case ADD_BRAND:
      return { ...state, brands: [...state.brands, action.brand], brand: {} as Brand }
    case GET_BRANDS:
      return { ...state, brands: action.brands, loading: false };
    case GET_BRAND_FOR_EDIT:
      return { ...state, brand: state.brands.find(brand => brand.brand_id = action.brand_id) || {} as Brand };
    case SELECT_BRAND:
      return {
        ...state,
        brands: state.brands.map(brand =>
          brand.brand_id === action.payload.brand_id ? { ...brand, checked: action.payload.checked } : brand
        )
      }
    case UPDATE_BRAND:
      return {
        ...state, brand: action.brand,
        brands: state.brands.map(brand =>
          brand.brand_id === action.brand.brand_id ?
            { ...brand, target_group: action.brand.target_group, brand_name: action.brand.brand_name } : brand)
      };
    case BRAND_FAIL:
      return { ...state, loading: false };
    case SET_BRAND_NAME:
      return { ...state, brand: { ...state.brand, brand_name: action.name } }
    case SET_BRAND_CLIENT_ID:
      return { ...state, brand: { ...state.brand, client_id: action.id } }
    case SET_TARGET_GROUP:
      return { ...state, brand: { ...state.brand, target_group: action.tg } }
    default:
      return state;
  }
}