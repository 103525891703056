import { GET_CONTACTS, GET_CONTACT, CONTACT_FAIL, SET_CONTACT_FORM_VALUE, ADD_CONTACT, RESET_CONTACT, UPDATE_AGENCY_CONTACT, UPDATE_CLIENT_CONTACT, ADD_CLIENT_CONTACT_FAIL, ADD_AGENCY_CONTACT_FAIL } from "../actions/types";
import { ClientContact, AgencyContact } from "../types";
import { ContactActionTypes } from "../actions/contact";

export interface ContactState {
  loading: boolean,
  contacts: (ClientContact | AgencyContact)[],
  contact: ClientContact | AgencyContact
}

const initialState: ContactState = {
  loading: true,
  contacts: [],
  contact: {} as ClientContact | AgencyContact
};

export default function (state = initialState, action: ContactActionTypes) {
  switch (action.type) {
    case ADD_CONTACT:
      return { ...state, contacts: [...state.contacts, action.contact], contact: {} as ClientContact | AgencyContact };
    case GET_CONTACTS:
      return { ...state, contacts: action.contacts, loading: false };
    case GET_CONTACT:
      return { ...state, contact: action.contact, loading: false };
    case RESET_CONTACT:
      return { ...state, contact: {} as ClientContact | AgencyContact };
    case UPDATE_AGENCY_CONTACT:
      /*  There are 4 possibilities:
      edit of an item in agency list and no change in agency_id
      edit of an item in agency list and change in agency_id
      add of an existing item, resulting in edit and change in agency_id to selected dsr agency
      add of an existing item, resulting in edit and change in agency_id to another agency
      */
      /* Check if item is in agency list */
      const existingAgencyContact = (state.contacts as AgencyContact[]).find(contact => contact.agency_contact_id === action.contact.agency_contact_id);
      if (existingAgencyContact) {
        /* No change in agency id - just update contact details */
        if (existingAgencyContact.agency_id === action.contact.agency_id) {
          return {
            ...state, contact: {} as ClientContact | AgencyContact,
            contacts: (state.contacts as AgencyContact[]).map(contact =>
              contact.agency_contact_id === action.contact.agency_contact_id ?
                action.contact : contact)
          };
        }
        /* Change in agency id - remove item from list */
        return {
          ...state, contact: {} as ClientContact | AgencyContact,
          contacts: (state.contacts as AgencyContact[]).filter(contact => contact.agency_contact_id !== action.contact.agency_contact_id)
        };
      }
      /* Updated contact now is assigned to current dsr agency */
      if (action.contact.agency_id === action.agency_id) {
        return {
          ...state, contact: {} as ClientContact | AgencyContact,
          contacts: [...state.contacts, action.contact]
        }
      }
      return state;

    case UPDATE_CLIENT_CONTACT:
      const existingContact = (state.contacts as ClientContact[]).find(contact => contact.client_contact_id === action.contact.client_contact_id);
      if (existingContact) {
        if (existingContact.client_id === action.contact.client_id) {
          return {
            ...state, contact: {} as ClientContact | AgencyContact,
            contacts: (state.contacts as ClientContact[]).map(contact =>
              contact.client_contact_id === action.contact.client_contact_id ?
                action.contact : contact)
          };
        }
        return {
          ...state, contact: {} as ClientContact | AgencyContact,
          contacts: (state.contacts as ClientContact[]).filter(contact => contact.client_contact_id !== action.contact.client_contact_id)
        };
      }
      if (action.contact.client_id === action.client_id) {
        return {
          ...state, contact: {} as ClientContact | AgencyContact,
          contacts: [...state.contacts, action.contact]
        }
      }
      return state;

    case ADD_CLIENT_CONTACT_FAIL:
      return { ...state, loading: false, contact: { ...state.contact, client_contact_id: action.contact.client_contact_id } };

    case ADD_AGENCY_CONTACT_FAIL:
      return { ...state, loading: false, contact: { ...state.contact, agency_contact_id: action.contact.agency_contact_id } };

    case SET_CONTACT_FORM_VALUE:
      return { ...state, contact: { ...state.contact, [action.payload.field]: action.payload.value } }

    case CONTACT_FAIL:
    default:
      return state;
  }
}