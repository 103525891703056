type LabelValue = {
  label: string;
  value: string;
}

export const API_ENDPOINT = "/api";

export const DEFAULT_TARGET_GROUPS = [
  'CS 2+',
  'CS 15-21 ABC',
  'CS 22-30 ABC F',
  'CS 31-40 ABC F',
  'CS 61+ ABC F'
];

export const TITLES = [
  'Mr.',
  'Ms.'
];

export const MEETING_TYPES = [
  'Telephonic',
  'Physical'
];

export const MARKET_DEFINITIONS = [
  'Urban',
  'Rural',
  'Urban + Rural'
]

export const DEFAULT_SEC: LabelValue[] = [
  { label: 'NCSS A', value: 'A' },
  { label: 'NCSS AB', value: 'AB' },
  { label: 'NCSS ABC', value: 'ABC' },
  { label: 'NCSS BCDE', value: 'BCDE' },
  { label: 'NCCS ALL', value: 'ABCDE' }
];

export const DEFAULT_GENDER: LabelValue[] = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'All Adults', value: 'MF' }
];

export const FROM_AGE = [
  '2', '15', '22', '31', '41', '51', '61+'
];

export const TO_AGE = [
  '8', '14', '21', '30', '40', '50', '60'
];

export const CONCERNS = [
  'Yes',
  'No',
  'Maybe'
];

export const DEFAULT_OTHER_INFO = [
  'CPRP not matching',
  'Rate is an issue',
  'Top 2 channels considered from the genre',
  'Channel does not have affinity with Client TG'
]

export const OTHERS = {
  name: 'Others (Text)',
  checked: false,
  value: ''
}
