import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonIcon, IonFooter } from '@ionic/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { arrowForwardSharp } from 'ionicons/icons';
import SearchUserContainer from '../components/SearchUserContainer';
import { useHistory } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { setDsrTaggedUsersAction } from '../actions/dsr';
import { RootState } from '../reducers';
import { User } from '../types';

const SearchUser: React.FC = () => {

  const dispatch = useDispatch();
  const taggedUsers = useSelector<RootState, User[]>(state => state.user.taggedUsers);

  const history = useHistory();

  const validateAndProceed = () => {
    if (taggedUsers.length > 0) {
      const ids = taggedUsers.map(user => user.user_id);
      dispatch(setDsrTaggedUsersAction(ids));
      history.push('/register');
    } else {
      dispatch(setAlert('Please tag at least one user', 'danger'));
    }
  }

  const validateAndSkip = () => {
    if (taggedUsers.length > 0) {
      dispatch(setAlert('Please remove tagged users to skip', 'danger'));
    } else {
      dispatch(setDsrTaggedUsersAction([]));
      history.push('/register');
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='home' />
          </IonButtons>
          <IonTitle>Tagged Users</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() =>
              validateAndSkip()
            }><IonIcon slot='end' icon={arrowForwardSharp}></IonIcon>Skip</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse='condense'>
          <IonToolbar>
            <IonTitle size='large'>Tagged Users</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SearchUserContainer />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          validateAndProceed()
        }>Proceed</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default SearchUser;
