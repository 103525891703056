import React, { useState } from 'react'
import { IonRadioGroup, IonItem, IonLabel, IonRadio, IonContent, IonSelect, IonSelectOption, IonHeader, IonToolbar, IonButtons, IonTitle, IonFooter, IonButton, IonIcon, IonList, IonListHeader, IonPage, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { DEFAULT_SEC, DEFAULT_GENDER, FROM_AGE, TO_AGE, DEFAULT_TARGET_GROUPS } from '../constants';
import { closeSharp } from 'ionicons/icons';
import { setAlert } from '../actions/alert';
import { useDispatch } from 'react-redux';

interface ContainerProps {
  onClose: any
}

const TargetGroupModal: React.FC<ContainerProps> = ({ onClose }) => {

  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [tgSec, setTgSec] = useState('');
  const [tgGender, setTgGender] = useState('');

  const dispatch = useDispatch();

  const onSubmit = () => {
    let tg = '';
    if (minAge && tgSec && tgGender) {
      if (minAge !== '61+' && maxAge) {
        tg = `CS ${minAge}-${maxAge} ${tgSec} ${tgGender}`;
      } else if (minAge === '61+') {
        tg = `CS ${minAge} ${tgSec} ${tgGender}`;
      } else {
        dispatch(setAlert('All fields are required', 'danger'));
      }
    } else {
      dispatch(setAlert('All fields are required', 'danger'));
    }
    if (tg) {
      if (DEFAULT_TARGET_GROUPS.find(item => item === tg)) {
        dispatch(setAlert('Buying TG already exists', 'danger'));
      } else {
        DEFAULT_TARGET_GROUPS.push(tg);
        onClose();
      }
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={(e) => onClose()}><IonIcon slot='start' icon={closeSharp}></IonIcon> Close</IonButton>
          </IonButtons>
          <IonTitle>New Buying TG</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-padding">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">New Buying TG</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem lines='none'>
          <IonLabel>Age</IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>From</IonLabel>
                <IonSelect mode='md' value={minAge} name='from' placeholder='From'
                  onIonChange={e => setMinAge(e.detail.value)}>
                  {FROM_AGE.map(minAge =>
                    <IonSelectOption key={minAge} value={minAge}>{minAge}</IonSelectOption>
                  )}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>To</IonLabel>
                <IonSelect mode='md' disabled={!minAge || minAge === '61+'} value={maxAge} slot='end' name='to' placeholder='To'
                  onIonChange={e => setMaxAge(e.detail.value)}>
                  {TO_AGE.filter(item => minAge ? +item > +minAge : true).map(maxAge =>
                    <IonSelectOption key={maxAge} value={maxAge}>{maxAge}</IonSelectOption>
                  )}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonList>
          <IonListHeader>
            <IonLabel><IonText><h2>Sec</h2></IonText></IonLabel>
          </IonListHeader>
          <IonRadioGroup value={tgSec} onIonChange={(e) => setTgSec(e.detail.value)}>
            {DEFAULT_SEC.map(sec =>
              <IonItem lines='none' key={sec.label}>
                <IonLabel>{sec.label}</IonLabel>
                <IonRadio mode='md' slot="start" value={sec.value} />
              </IonItem>)
            }
          </IonRadioGroup>
        </IonList>

        <IonList>
          <IonRadioGroup value={tgGender} onIonChange={(e) => setTgGender(e.detail.value)}>
            <IonListHeader>
              <IonLabel><IonText><h2>Gender</h2></IonText></IonLabel>
            </IonListHeader>
            {DEFAULT_GENDER.map(gender =>
              <IonItem lines='none' key={gender.label}>
                <IonLabel>{gender.label}</IonLabel>
                <IonRadio mode='md' slot="start" value={gender.value} />
              </IonItem>)
            }
          </IonRadioGroup>
        </IonList>
        <IonItem></IonItem>
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>Add</IonButton>
      </IonFooter>
    </IonPage>
  )
}

export default TargetGroupModal;