import axios from 'axios';
import { SET_DSR_DATE, SET_DSR_CLIENT, SET_MEETING_TYPE, SET_MEETING_SUMMARY, ADD_DSR_FAIL, CREATE_DSR_SUMMARY, SET_DSR_AGENCY, SET_DSR_AGENCY_CLIENTS, SET_DSR_TAGGED_USERS, ADD_DSR, TG_UPDATE, SET_DSR_CONTACT } from './types';
import { API_ENDPOINT } from '../constants';
import { setAlert } from './alert';
import { Client, Agency, Campaign, ClientContact, AgencyContact, DsrForm } from '../types';
import { AppThunk } from '../store';

interface SetDsrTaggedUsersAction {
  type: typeof SET_DSR_TAGGED_USERS,
  ids: number[]
}

interface SetDsrDateAction {
  type: typeof SET_DSR_DATE,
  dsrDate: string
}

interface SetDsrClientAction {
  type: typeof SET_DSR_CLIENT,
  client: Client
}

interface SetDsrAgencyAction {
  type: typeof SET_DSR_AGENCY,
  agency: Agency
}

interface SetDsrAgencyClientAction {
  type: typeof SET_DSR_AGENCY_CLIENTS,
  payload: { checked: boolean, client: Client }
}

interface SetDsrContactAction {
  type: typeof SET_DSR_CONTACT,
  payload: { checked: boolean, contact: ClientContact | AgencyContact }
}

interface SetMeetingTypeAction {
  type: typeof SET_MEETING_TYPE,
  meetingType: string
}

interface SetMeetingSummaryAction {
  type: typeof SET_MEETING_SUMMARY,
  meetingSummary: string
}

interface ResetTGAction {
  type: typeof TG_UPDATE,
  tgUpdate: boolean
}

interface CreateSummaryAction {
  type: typeof CREATE_DSR_SUMMARY,
  payload: { user_id: string, campaigns: Campaign[] }
}

interface AddDsrAction {
  type: typeof ADD_DSR
}

interface AddDsrFailAction {
  type: typeof ADD_DSR_FAIL
}

export type DsrActionTypes = SetDsrTaggedUsersAction | SetDsrDateAction | SetDsrClientAction | SetDsrAgencyAction | SetDsrAgencyClientAction | SetDsrContactAction | SetMeetingTypeAction | SetMeetingSummaryAction | ResetTGAction | CreateSummaryAction | AddDsrAction | AddDsrFailAction;

export const setDsrTaggedUsersAction = (ids: number[]): DsrActionTypes => {
  return ({
    type: SET_DSR_TAGGED_USERS,
    ids
  });
};

export const setDsrDateAction = (dsrDate: string): DsrActionTypes => {
  return ({
    type: SET_DSR_DATE,
    dsrDate
  })
};

export const setDsrClientAction = (client: Client): DsrActionTypes => {
  return ({
    type: SET_DSR_CLIENT,
    client
  })
};

export const setDsrAgencyAction = (agency: Agency): DsrActionTypes => {
  return ({
    type: SET_DSR_AGENCY,
    agency
  })
};

export const setDsrAgencyClientAction = (checked: boolean, client: Client): DsrActionTypes => {
  return ({
    type: SET_DSR_AGENCY_CLIENTS,
    payload: { checked, client }
  })
};

export const setDsrContactAction = (checked: boolean, contact: ClientContact | AgencyContact): DsrActionTypes => {
  return ({
    type: SET_DSR_CONTACT,
    payload: { checked, contact }
  })
}

export const setMeetingTypeAction = (meetingType: string): DsrActionTypes => {
  return ({
    type: SET_MEETING_TYPE,
    meetingType
  })
};

export const setMeetingSummaryAction = (meetingSummary: string): DsrActionTypes => {
  return ({
    type: SET_MEETING_SUMMARY,
    meetingSummary
  })
};

export const resetTGAction = (tgUpdate: boolean): DsrActionTypes => {
  return ({
    type: TG_UPDATE,
    tgUpdate
  })
}

export const createSummaryAction = (user_id: string, campaigns: Campaign[]): DsrActionTypes => {
  return ({
    type: CREATE_DSR_SUMMARY,
    payload: { user_id, campaigns }
  });
};

const addDsrAction = (): DsrActionTypes => {
  return ({
    type: ADD_DSR
  })
}

const addDsrFailAction = (): DsrActionTypes => {
  return ({
    type: ADD_DSR_FAIL
  })
}

export const addDsr = (dsr: DsrForm): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/dsrs/create`, dsr, config);
    if (res.data.status) {
      dispatch(addDsrAction())
      dispatch(setAlert('DSR successfully submitted', 'success'));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(addDsrFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(addDsrFailAction());
  }
};