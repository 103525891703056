import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { getAgencyClients, updateAgencyClients } from '../actions/agency';
import SelectAgencyClientContainer from '../components/SelectAgencyClientContainer';
import { RootState } from '../reducers';
import { Client } from '../types';


const SelectAgencyClient: React.FC = () => {

  const dispatch = useDispatch();
  const agencyClients = useSelector<RootState, Client[]>(state => state.agency.agencyClients);
  const dsrAgencyClients = useSelector<RootState, Client[]>(state => state.dsr.dsrAgencyClients);

  const { agency_id } = useParams();
  const history = useHistory();


  useEffect(() => {
    if (agency_id) {
      dispatch(getAgencyClients(agency_id));
    }  // eslint-disable-next-line
  }, []);


  const validateAndProceed = () => {
    if (dsrAgencyClients.length > 0) {
      dispatch(updateAgencyClients(dsrAgencyClients, history, agency_id!));
    } else {
      dispatch(setAlert('Please select at least one client to proceed', 'danger'));
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>Select Clients</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Select Clients</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SelectAgencyClientContainer agencyClients={agencyClients} />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          validateAndProceed()
        }>Proceed</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default SelectAgencyClient;
