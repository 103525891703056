import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton, IonSpinner } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { getContacts } from '../actions/contact';
import SearchContactContainer from '../components/SearchContactContainer';
import { RootState } from '../reducers';
import { ClientContact, AgencyContact, Client } from '../types';

const SelectContact: React.FC = () => {

  const dispatch = useDispatch();
  const dsrAgencyClients = useSelector<RootState, Client[]>(state => state.dsr.dsrAgencyClients);
  const dsrContacts = useSelector<RootState, (ClientContact | AgencyContact)[]>(state => state.dsr.dsrContacts);
  const contacts = useSelector<RootState, (ClientContact | AgencyContact)[]>(state => state.contact.contacts);
  const loading = useSelector<RootState, boolean>(state => state.contact.loading);

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      dispatch(getContacts(id, location.pathname.includes('agencies') ? true : false));
    }
    // eslint-disable-next-line
  }, []);

  const agency = location.pathname.includes('agencies') ? true : false;

  const validateAndProceed = () => {
    if (dsrContacts.length > 0) {
      const clients = agency ? dsrAgencyClients.map(client => client.client_id).join(',') : id;
      history.push(`/brands/${clients}`);
    } else {
      dispatch(setAlert('Please select at least one contact to proceed', 'danger'));
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../../home" />
          </IonButtons>
          <IonTitle>Contact Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Contact Details</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading && contacts === null ?
          (<IonSpinner />) :
          (<SearchContactContainer contacts={contacts} agency={agency} />)
        }
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          validateAndProceed()
        }>Proceed</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default SelectContact;
