import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonToast, IonAlert } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import { useSelector } from 'react-redux';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Alert from './components/Alert';
import { setAccept, setAuthToken, setBasicAuth } from './utils/setCommonHeaders';
import SearchClient from './pages/SearchClient';
import DsrList from './pages/DsrList';
import DsrDetailPage from './pages/DsrDetailPage';
import HoD from './pages/HoD';
import SearchUser from './pages/SearchUser';
import RegisterCall from './pages/RegisterCall';
import ClientPage from './pages/ClientPage';
import SelectContact from './pages/SelectContact';
import ContactPage from './pages/ContactPage';
import SelectBrand from './pages/SelectBrand';
import Brand from './pages/Brand';
import CampaignPage from './pages/CampaignPage';
import DsrSummary from './pages/DsrSummary';
import SearchAgency from './pages/SearchAgency';
import AgencyPage from './pages/AgencyPage';
import SelectAgencyClient from './pages/SelectAgencyClient';
import Concern from './pages/Concern';
import PrivateRoute from './routing/PrivateRoute';
import NotFound from './pages/NotFound';
import { RootState } from './reducers';

setAccept();
setBasicAuth();

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App: React.FC = () => {

  const [showAlert, setShowAlert] = useState(false);

  const isServiceWorkerInitialized = useSelector<RootState, boolean>(
    state => state.worker.serviceWorkerInitialized,
  );

  const isServiceWorkerUpdated = useSelector<RootState, boolean>(
    state => state.worker.serviceWorkerUpdated,
  );

  const serviceWorkerRegistration = useSelector<RootState, ServiceWorkerRegistration>(
    state => state.worker.serviceWorkerRegistration,
  );

  useEffect(() => {
    if (isServiceWorkerUpdated) {
      setShowAlert(true);
    }
  }, [isServiceWorkerUpdated]);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  return (<IonApp>
    <Alert />
    {isServiceWorkerInitialized && (
      <IonToast isOpen message='This app is now available off-line' color='primary' duration={2000} />
    )}
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert(false)}
      header={'App Update Available'}
      message={`An updated version of this app is available.  Update now ?`}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            setShowAlert(false);
          }
        },
        {
          text: 'Ok',
          handler: () => {
            setShowAlert(false);
            updateServiceWorker();
          }
        }
      ]}
    />
    <IonReactRouter>
      <IonRouterOutlet>

        <Route exact path='/'>
          <Redirect to='/login' />
        </Route>

        <Route path='/login' exact>
          <Login />
        </Route>

        <PrivateRoute path='/brands/add' exact>
          <Brand />
        </PrivateRoute>

        <PrivateRoute path='/brands/tg/:brand_id' exact>
          <Brand />
        </PrivateRoute>

        <PrivateRoute path='/brands/edit/:brand_id' exact>
          <Brand />
        </PrivateRoute>

        <PrivateRoute path='/brands/:clients' exact>
          <SelectBrand />
        </PrivateRoute>


        <PrivateRoute path='/campaign/:brand_id' exact>
          <CampaignPage />
        </PrivateRoute>

        <PrivateRoute path='/concern' exact>
          <Concern />
        </PrivateRoute>

        <PrivateRoute path='/clients/contacts/add' exact>
          <ContactPage />
        </PrivateRoute>

        <PrivateRoute path='/clients/contacts/edit/:contact_id' exact>
          <ContactPage />
        </PrivateRoute>

        <PrivateRoute path='/clients/contacts/:id' exact>
          <SelectContact />
        </PrivateRoute>

        <PrivateRoute path='/clients/add' exact>
          <ClientPage />
        </PrivateRoute>

        <PrivateRoute path='/clients/edit/:client_id' exact>
          <ClientPage />
        </PrivateRoute>

        <PrivateRoute path='/clients' exact>
          <SearchClient />
        </PrivateRoute>

        <PrivateRoute path='/agencies/add' exact>
          <AgencyPage />
        </PrivateRoute>

        <PrivateRoute path='/agencies/clients/add' exact>
          <ClientPage />
        </PrivateRoute>

        <PrivateRoute path='/agencies/clients/:agency_id' exact>
          <SelectAgencyClient />
        </PrivateRoute>


        <PrivateRoute path='/agencies/edit/:agency_id' exact>
          <AgencyPage />
        </PrivateRoute>

        <PrivateRoute path='/agencies/contacts/add' exact>
          <ContactPage />
        </PrivateRoute>

        <PrivateRoute path='/agencies/contacts/edit/:contact_id' exact>
          <ContactPage />
        </PrivateRoute>

        <PrivateRoute path='/agencies/contacts/:id' exact>
          <SelectContact />
        </PrivateRoute>

        <PrivateRoute path='/agencies' exact>
          <SearchAgency />
        </PrivateRoute>

        <PrivateRoute path='/dsrs/summary' exact>
          <DsrSummary />
        </PrivateRoute>

        <PrivateRoute path='/dsrs/:dsr_id' exact>
          <DsrDetailPage />
        </PrivateRoute>

        <PrivateRoute path='/dsrs' exact>
          <DsrList />
        </PrivateRoute>

        <PrivateRoute path='/home' exact>
          <Home />
        </PrivateRoute>

        <PrivateRoute path='/register' exact>
          <RegisterCall />
        </PrivateRoute>

        <PrivateRoute path='/hod' exact>
          <HoD />
        </PrivateRoute>

        <PrivateRoute path='/tag' exact>
          <SearchUser />
        </PrivateRoute>

        <Route path='*'>
          <NotFound />
        </Route>

      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>);
}

export default App;
