import React from 'react';
import { IonItem, IonLabel, IonList, IonText, IonButton, IonIcon, IonCard } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { pencilOutline } from 'ionicons/icons';
import { Campaign, User, ClientContact, AgencyContact } from '../types';
import { DsrState } from '../reducers/dsr';

interface ContainerProps {
  dsr: DsrState,
  campaigns: Campaign[],
  taggedUsers: User[]
}

const DsrSummaryContainer: React.FC<ContainerProps> = ({ taggedUsers, dsr, campaigns }) => {

  const history = useHistory();

  return (
    <IonCard>
      <IonList>
        {taggedUsers.length > 0 && (
          <IonItem>
            <IonLabel className='ion-text-wrap'>
              <IonText>
                <h3>Tagged users</h3>
              </IonText>
              <IonText color='primary'>
                <h2>{taggedUsers.map(user => user.name).join(', ')}</h2>
              </IonText>
            </IonLabel>
          </IonItem>
        )}
        <IonItem>
          <IonLabel className='ion-text-wrap'>
            <IonText><h3>Organization Name</h3></IonText>
            <IonText color='primary'>
              <h2>{dsr.dsrClient && dsr.dsrClient.client_name ? dsr.dsrClient.client_name : dsr.dsrAgency && dsr.dsrAgency.agency_name}</h2>
            </IonText>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <IonText><h3>Contact Details</h3></IonText>
            {dsr.dsrContacts.map(contact => (
              <IonText color="primary" key={dsr.dsrClient ? (contact as ClientContact).client_contact_id : (contact as AgencyContact).agency_contact_id}>
                <h2>{contact.contact_name} - {contact.designation}</h2>
              </IonText>
            ))}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className='ion-text-wrap'>
            <IonText><h3>Meeting Summary</h3></IonText>
            <IonText color="primary">
              <h2>{dsr.meetingSummary}</h2>
            </IonText>
          </IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonLabel>Brand-wise Information Summary</IonLabel>
        </IonItem>
        {campaigns.map(campaign => (
          <IonCard key={campaign.brand_id}>
            <IonItem className='ion-text-center'>
              <IonLabel className='ion-text-wrap'>{campaign.client_name} | {campaign.brand_name}
              </IonLabel>
              <IonButton fill='clear' slot='end' onClick={() => history.push(`/campaign/${campaign.brand_id}`)}><IonIcon slot='icon-only' icon={pencilOutline}></IonIcon></IonButton>
            </IonItem>
            <IonItem>
              <IonLabel><IonText><h3>Target Group</h3></IonText></IonLabel>
              <IonLabel>
                <IonText color="primary">
                  <h2>{campaign.target_group}</h2>
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel><IonText><h3>Key Markets</h3></IonText></IonLabel>
              <IonLabel className='ion-text-wrap'>
                <IonText color="primary">
                  <h2>{campaign.keyMarkets && campaign.keyMarkets.map(market => market.name).join(', ')}
                    {campaign.keyMarkets && campaign.keyMarkets.length > 0 && campaign.otherMarket && <span>, </span>}
                    {campaign.otherMarket && <span>{campaign.otherMarket}</span>}</h2>
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <IonText><h3>Key Market Definition</h3></IonText></IonLabel>
              <IonLabel>
                <IonText color="primary">
                  <h2>{campaign.marketDefinition}</h2>
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <IonText><h3>Genre Under Consideration</h3></IonText></IonLabel>
              <IonLabel className='ion-text-wrap'>
                <IonText color="primary">
                  <h2>{campaign.genres.map(genre => genre.name).join(', ')}</h2>
                </IonText>
              </IonLabel>
            </IonItem>
            {campaign.concern && (
              <IonCard>
                <IonItem >
                  <IonLabel className="ion-text-wrap">
                    <IonText><h3>Is channel part of Campaign</h3></IonText></IonLabel>
                  <IonLabel>
                    <IonText color="primary">
                      <h2>{campaign.concern.partOfCampaign}</h2>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <IonText><h3>Other Info</h3></IonText></IonLabel>
                  <IonLabel className='ion-text-wrap'>
                    <IonText color="primary">
                      <h2>{campaign.concern.otherInfo.join(', ')}
                        {campaign.concern.otherInfo && campaign.concern.otherInfo.length > 0 && campaign.concern.others && <span>, </span>}
                        {campaign.concern.others && <span>{campaign.concern.others}</span>}</h2>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}
          </IonCard>
        ))}
      </IonList>
      <IonItem>
        <IonLabel>&nbsp;</IonLabel>
      </IonItem>
    </IonCard>
  )
};

export default DsrSummaryContainer;