import React from 'react'
import { IonItem, IonLabel, IonText, IonCard, IonChip } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_LOADING } from '../actions/types';
import { Dsr } from '../types';

interface ContainerProps {
  dsrs: Dsr[]
}

const DsrList: React.FC<ContainerProps> = ({ dsrs }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      {dsrs.map(dsr => (
        <IonCard key={dsr.dsr_id} onClick={() => {
          dispatch({ type: SET_LOADING });
          history.push(`/dsrs/${dsr.dsr_id}`);
        }}>
          <IonItem>
            <IonChip slot='end' color={dsr.client_id ? 'primary' : 'secondary'} outline>
              <IonLabel>{dsr.client_id ? 'Client' : 'Agency'}</IonLabel>
            </IonChip>
            <IonLabel className='ion-text-wrap'>
              <IonText>{dsr.dsr_date}</IonText>
              <IonText>
                <p>{dsr.user.name}</p>
              </IonText>
              <IonText>
                {dsr.dsr_contact.map((contact: any) => (
                  <p key={contact.id}>{contact.contact_name} - {contact.designation}</p>
                ))}
              </IonText>
            </IonLabel>
          </IonItem>
        </IonCard>
      ))}
    </>
  )
}

export default DsrList;