import React, { useState } from 'react';
import { setAlert } from '../actions/alert';
import { IonItem, IonSearchbar, IonButton, IonIcon } from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
import { useDispatch } from 'react-redux';

interface ContainerProps {
  placeholder: string,
  searchAction: any
}

const Search: React.FC<ContainerProps> = ({ searchAction, placeholder }) => {

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  const onSearchClicked = (e: any) => {
    e.preventDefault();
    if (searchText.length > 2) {
      dispatch(searchAction(searchText));
    } else {
      dispatch(setAlert('Enter at least 3 characters', 'danger'));
    }
  };

  return (
    <IonItem lines='none'>
      <IonSearchbar value={searchText} placeholder={`Search ${placeholder}`}
        onKeyDown={e => e.key === 'Enter' && onSearchClicked(e)}
        onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
      <IonButton fill='clear' onClick={e => onSearchClicked(e)}><IonIcon slot='icon-only' icon={searchOutline}></IonIcon></IonButton>
    </IonItem>
  )
}

export default Search;