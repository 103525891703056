import { combineReducers } from 'redux';
import alert from './alert';
import user from './user';
import dsr from './dsr';
import hod from './hod';
import client from './client';
import agency from './agency';
import contact from './contact';
import brand from './brand';
import campaign from './campaign';
import review from './review';
import worker from './worker';

const rootReducer = combineReducers({
  alert,
  user,
  dsr,
  review,
  hod,
  client,
  campaign,
  agency,
  contact,
  brand,
  worker
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>